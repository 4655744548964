<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="公会编号" prop="guildNo" >
        <a-input v-model="form.guildNo" placeholder="请输入公会编号" />
      </a-form-model-item>
      <a-form-model-item label="姓名" prop="name" >
        <a-input v-model="form.name" placeholder="请输入姓名" />
      </a-form-model-item>
      <a-form-model-item label="用户id" prop="userId" >
        <a-input v-model="form.userId" placeholder="请输入用户id" />
      </a-form-model-item>
      <a-form-model-item label="手机号" prop="mobile" >
        <a-input v-model="form.mobile" placeholder="请输入手机号" />
      </a-form-model-item>
      <a-form-model-item label="微信号" prop="wxNumber" >
        <a-input v-model="form.wxNumber" placeholder="请输入微信号" />
      </a-form-model-item>
      <a-form-model-item label="目前所在平台名称" prop="currentPlatformName" >
        <a-input v-model="form.currentPlatformName" placeholder="请输入目前所在平台名称" />
      </a-form-model-item>
      <a-form-model-item label="申请入驻的品类id--对应interest_label一级分类，多个逗号隔开" prop="applyLabelIds" >
      </a-form-model-item>
      <a-form-model-item label="是否具备公司资质" prop="isCompany" >
      </a-form-model-item>
      <a-form-model-item label="其他补充信息" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入其他补充信息" />
      </a-form-model-item>
      <a-form-model-item label="目前所在平台最新三个月后台流水截图" prop="streamImg" >
        <file-upload v-model="form.streamImg" type="image"></file-upload>
      </a-form-model-item>
      <a-form-model-item label="公会后台团队人数截图以及团队的微信群截图" prop="wxGroupImg" >
        <file-upload v-model="form.wxGroupImg" type="image"></file-upload>
      </a-form-model-item>
      <a-form-model-item label="上个月平台给您公会打款的收款截图" prop="collectionImg" >
        <file-upload v-model="form.collectionImg" type="image"></file-upload>
      </a-form-model-item>
      <a-form-model-item label="邀请码" prop="inviteCode" >
        <a-input v-model="form.inviteCode" placeholder="请输入邀请码" />
      </a-form-model-item>
      <a-form-model-item label="申请状态" prop="applyStatus" >
        <a-select placeholder="请选择申请状态" v-model="form.applyStatus">
          <a-select-option v-for="(d, index) in applyStatusOptions" :key="index" :value="d.value" >{{ d.label }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="驳回原因" prop="rejectReason" >
        <a-input v-model="form.rejectReason" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="礼物结算比例" prop="giftProportion" >
        <a-input v-model="form.giftProportion" placeholder="请输入礼物结算比例" />
      </a-form-model-item>
      <a-form-model-item label="公会名称" prop="guildName" >
        <a-input v-model="form.guildName" placeholder="请输入公会名称" />
      </a-form-model-item>
      <a-form-model-item label="公会简介" prop="introduction" >
        <a-input v-model="form.introduction" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="是否具有配置礼物结算抽成比例权限" prop="giftProportionPermission" >
        <a-input v-model="form.giftProportionPermission" placeholder="请输入是否具有配置礼物结算抽成比例权限" />
      </a-form-model-item>
      <a-form-model-item label="是否推荐" prop="isRecommend" >
      </a-form-model-item>
      <a-form-model-item label="公会logo" prop="guildLogo" >
        <file-upload v-model="form.guildLogo" type="image"></file-upload>
      </a-form-model-item>
      <a-form-model-item label="与大神续约是否需要大神同意" prop="renewPermission" >
        <a-input v-model="form.renewPermission" placeholder="请输入与大神续约是否需要大神同意" />
      </a-form-model-item>
      <a-form-model-item label="平台公会结算比例" prop="platformProp" >
        <a-input v-model="form.platformProp" placeholder="请输入平台公会结算比例" />
      </a-form-model-item>
      <a-form-model-item label="公司类型" prop="companyType" >
      </a-form-model-item>
      <a-form-model-item label="公司/个人名称" prop="companyName" >
        <a-input v-model="form.companyName" placeholder="请输入公司/个人名称" />
      </a-form-model-item>
      <a-form-model-item label="营业执照" prop="license" >
        <a-input v-model="form.license" placeholder="请输入营业执照" />
      </a-form-model-item>
      <a-form-model-item label="手续费比例" prop="premiumPercent" >
        <a-input v-model="form.premiumPercent" placeholder="请输入手续费比例" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getGuild, addGuild, updateGuild } from '@/api/biz/guild'

export default {
  name: 'CreateForm',
  props: {
    applyStatusOptions: {
      type: Array,
      required: true
    }
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        guildNo: null,

        name: null,

        userId: null,

        mobile: null,

        wxNumber: null,

        currentPlatformName: null,

        applyLabelIds: [],

        isCompany: '0',

        remark: null,

        streamImg: null,

        wxGroupImg: null,

        collectionImg: null,

        inviteCode: null,

        applyStatus: null,

        rejectReason: null,

        giftProportion: null,

        guildName: null,

        introduction: null,

        giftProportionPermission: null,

        isRecommend: '0',

        guildLogo: null,

        renewPermission: null,

        platformProp: null,

        companyType: null,

        companyName: null,

        license: null,

        premiumPercent: null,

        createTime: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        guildNo: null,
        name: null,
        userId: null,
        mobile: null,
        wxNumber: null,
        currentPlatformName: null,
        applyLabelIds: [],
        isCompany: '0',
        remark: null,
        streamImg: null,
        wxGroupImg: null,
        collectionImg: null,
        inviteCode: null,
        applyStatus: null,
        rejectReason: null,
        giftProportion: null,
        guildName: null,
        introduction: null,
        giftProportionPermission: null,
        isRecommend: '0',
        guildLogo: null,
        renewPermission: null,
        platformProp: null,
        companyType: null,
        companyName: null,
        license: null,
        premiumPercent: null,
        createTime: null,
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getGuild({"id":id}).then(response => {
        this.form = response.data
        if (this.form.applyLabelIds !== null) {
          this.form.applyLabelIds = this.form.applyLabelIds.split(',')
        } else {
          this.form.applyLabelIds = undefined
        }
        this.open = true
        this.formTitle = '修改'
      })
    },

    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          this.form.applyLabelIds = this.form.applyLabelIds.join(',')
          if (this.form.id !== undefined && this.form.id !== null) {
            updateGuild(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addGuild(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    },


  }
}
</script>
